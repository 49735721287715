// src/utilis/withOverlayPopup.js
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import '../style/overlayPopup.css';
import OtpPopup from './Sections/OTP/OtpPopup';

const withOverlayPopup = (WrappedComponent) => {
  return (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const openPopup = () => {
        setIsOpen(true);
    }
    const closePopup = () => {
        setIsOpen(false);
    }
    return (
      <>
        <WrappedComponent {...props} openPopup={openPopup} />
        {isOpen &&
          ReactDOM.createPortal(
            <>
              <div className="overlay-background" onClick={closePopup} />
              <div className="overlay-popup">
                <div className="overlay-content">
                  <button className="close-button" onClick={closePopup}>
                    &times;
                  </button>
                  {<OtpPopup/> || <p>Default Popup Content</p>}
                </div>
              </div>
            </>,
            document.getElementById('popup-root')
          )}
      </>
    );
  };
};

export default withOverlayPopup;
