import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/add/1.jpg";
import AddImage2 from "../../assets/img/add/2.avif";
import AddImage3 from "../../assets/img/add/3.jpg";
import AddImage4 from "../../assets/img/add/4.jpg";

export default function Services() {
  return (
    <Wrapper id="services">
      {/* <div className="verylightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div> */}
      <div className="verylightBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className=" font40 extraBold">Our Awesome Services</h1>
            <p className="font16">
              Simple IoT solutions for every business needs
              {/* <br />
              labore et dolore magna aliquyam erat, sed diam voluptua. */}
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="dashboard"
                title="IoT Dashboard"
                subtitle="Centralized Interface that provides real-time monitoring, control, and analysis of connected devices and systems within the Internet of Things (IoT) ecosystem."
                customTitleStyles={{ marginBottom: "10px" }} // Space below the title
                customSubtitleStyles={{ marginTop: "0px" }} // Space above the subtitle
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="monitor"
                title="Flow Meter Monitoring"
                subtitle="Accurate and Real-Time Tracking of fluid flow in pipelines and systems. Whether it's water, gas, or oil, our solution empowers you to maintain optimal efficiency."
                customTitleStyles={{ marginBottom: "0px" }} // Space below the title
                customSubtitleStyles={{ marginTop: "10px" }} // Space above the subtitle
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="browser"
                title="IoT Gateways"
                subtitle="Bridges the Gap between IoT devices and the Cloud, enabling seamless connectivity, data processing, and device management."
                customTitleStyles={{ marginBottom: "15px" }} // Space below the title
                customSubtitleStyles={{ marginTop: "10px" }} // Space above the subtitle
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="printer"
                title="Predictive Maintenance"
                subtitle="Leverages IoT sensors and advanced analytics to anticipate equipment failures before they occur. Ensures your equipment runs smoothly and efficiently."
                customTitleStyles={{ marginBottom: "0px" }} // Space below the title
                customSubtitleStyles={{ marginTop: "1px" }} // Space above the subtitle
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>

        </div>
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h4 className="font15 semiBold">A few words about company</h4>
                <h2 className="font40 extraBold">
                  State of Art Industrial <span className="gradIoT"> IoT </span>
                  solution
                </h2>
                <p className="font16">
                  Our state-of-the-art Industrial IoT solutions empower
                  businesses to optimize operations and enhance real-time
                  decision-making. We offer tailored software solutions that seamlessly integrate IoT technologies
                  to improve asset management, predictive maintenance, and overall productivity.
                </p>
                <ButtonsRow
                  className="flexNullCenter"
                  style={{ margin: "40px 0" }}
                >
                  <div style={{ width: "190px" }}>
                    <FullButton
                      title="Get Started"
                      action={() => alert("clicked")}
                    />
                  </div>
                  <div style={{ width: "190px", marginLeft: "15px" }}>
                    <a href="#contact">
                      <FullButton title="Contact Us" />
                    </a>
                  </div>
                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={AddImage1} alt="office" />
                    </AddImgWrapp1>
                    <AddImgWrapp2>
                      <img src={AddImage2} alt="office" />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img src={AddImage3} alt="office" />
                    </AddImgWrapp3>
                    <AddImgWrapp4>
                      <img src={AddImage4} alt="office" />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  display: flex; /* Enable flexbox */
  justify-content: center; /* Align items horizontally in the center */
  align-items: center; /* Align items vertically in the center (if applicable) */
  flex-wrap: nowrap; /* Ensure items do not wrap to a new line */
  gap: 20px; /* Optional: Add spacing between the items */
  overflow-x: auto; /* Optional: Enable horizontal scrolling for additional items */
  padding: 20px 0; /* Optional: Add padding for spacing */
`;

const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
  text-align: center;
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
    
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
    margin-top: 40px; /* Adjust the value for more/less space */
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -80px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 50% 6% 5% 6%;
  img {
    width: 100%;
    margin-bottom: 10px;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 50%;
  margin-left: 25%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 70%;
  margin: 0 10px;
  margin-top: -20%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
