import React from "react";
import styled from "styled-components";
import "../../../style/otpsectwo.css";
import bg from "../../../assets/img/background/pricing-advantage-bg.png";

export default function Otpsectwo() {
  return (
    <Wrapper id="services">
      <div className="otpsectwo">
        <img className="bgimg" src={bg} alt="" />
      </div>
      <div className="text">
        <h2 className="sms">Send Bulk SMS Online</h2>
        <p className="subhead">
          Reliable & Best Bulk SMS Service Provider <br />
          In India
        </p>

        <p className="offering">
          2Factor offers HTTP APIs to send bulk SMS online, Perform OTP
          verification ( via SMS & Whatsapp OTP), Send Bulk SMS marketing
          campaigns, Send transactional SMS to clients & SMS plugins to
          integrate SMS sending from CMS , CRM, Shopping cart softwares
        </p>
      </div>
      <div className="btn-cont">
        <button className="btn-tr primaryBg hover:bg-blue-800">SignUp For Free Trail</button>
        <button className="btn-pr primaryBg hover:bg-blue-800">Checkout SMS Pricing</button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  height: 100vh;
`;
