import React from "react";
import { Helmet } from "react-helmet";
import Landing from "./screens/Landing.jsx";
import OtpLanding from "./screens/OtpLanding.jsx";
import IotLanding from "./screens/IotLanding.jsx";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

export default function App() {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Funnel+Display&family=Inter:opsz@14..32&family=Poppins&family=Syne:wght@400..800&display=swap" rel="stylesheet"/>

      </Helmet>
      <Router>
      <Routes>
          <Route path="/" element={<OtpLanding />} />
          {/* <Route path="/otp" element={<OtpLanding />} />
          <Route path="/iot" element={<IotLanding />} /> */}
      </Routes>
      </Router>
      
    </>
  );
}

