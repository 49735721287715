import React from "react";
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/OTP/Otpheader";
import Services from "../components/Sections/OTP/Services";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer";
import Otpsectwo from "../components/Sections/OTP/otpsectwo";

export default function OtpLanding() {
  return (
    <>
      <TopNavbar />
      <Header />
      <Otpsectwo />
      <Contact />
      <Footer />
    </>
  );
}
