import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
// import RollerIcon from "../../assets/svg/Services/RollerIcon";
import Dashboard from "../../assets/svg/Services/DashboardIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../assets/svg/Services/GatewayIcon";
import PrinterIcon from "../../assets/svg/Services/PredictIcon";
import CheckMark from "../../assets/svg/Checkmark";

export default function PricingTable({
  icon,
  price,
  title,
  text,
  offers,
  action,
}) {
  let getIcon;

  switch (icon) {
    case "dashboard":
      getIcon = <Dashboard />;
      break;
    case "monitor":
      getIcon = <MonitorIcon />;
      break;
    case "browser":
      getIcon = <BrowserIcon />;
      break;
    case "printer":
      getIcon = <PrinterIcon />;
      break;
    default:
      getIcon = <Dashboard />;
      break;
  }

  return (
    <Wrapper className="newBg radius8 shadow">
      <div className="flexSpaceCenter">
        {getIcon}
        <p className="font30 extraBold">{price}</p>
      </div>
      <div style={{ margin: "30px 0" }}>
        <h4 className="font30 extraBold">{title}</h4>
        <p className="font13">{text}</p>
      </div>
      <div>
        {offers
          ? offers.map((item, index) => (
              <div
                className="flexNullCenter"
                style={{ margin: "15px 0" }}
                key={index}
              >
                <div
                  style={{
                    position: "relative",
                    top: "-1px",
                    marginRight: "15px",
                  }}
                >
                  {item.cheked ? (
                    <div style={{ minWidth: "20px" }}>
                      <CheckMark />
                    </div>
                  ) : (
                    <div style={{ minWidth: "20px" }}></div>
                  )}
                </div>
                <p className="font20 extraBold">{item.name}</p>
              </div>
            ))
          : null}
      </div>
      <div style={{ maxWidth: "120px", margin: "30px auto 0 auto" }}>
        <a href="#contact" style={{ textDecoration: "none" }}>
          <FullButton title="Buy" />
        </a>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 6px;
  transition: box-shadow 0.3s ease-in;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.5) 0px 8px 10px;
    transform: scale(1.02);
`;
