import React from "react";
import styled from "styled-components";
// Assets
// import RollerIcon from "../../assets/svg/Services/RollerIcon";
import Dashboard from "../../assets/svg/Services/DashboardIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../assets/svg/Services/GatewayIcon";
import PrinterIcon from "../../assets/svg/Services/PredictIcon";

export default function ServiceBox({ icon, title, subtitle, customTitleStyles, customSubtitleStyles }) {
  let getIcon;

  switch (icon) {
    case "dashboard":
      getIcon = <Dashboard />;
      break;
    case "monitor":
      getIcon = <MonitorIcon />;
      break;
    case "browser":
      getIcon = <BrowserIcon />;
      break;
    case "printer":
      getIcon = <PrinterIcon />;
      break;
    default:
      getIcon = <Dashboard />;
      break;
  }

  return (
    <Wrapper className="flex flexColumn">
      <IconStyle>{getIcon}</IconStyle>
      <TitleStyle className="font20 extraBold" style={customTitleStyles}>
        {title}
      </TitleStyle>
      <SubtitleStyle className="font14" style={customSubtitleStyles}>
        {subtitle}
      </SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;

const IconStyle = styled.div`
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;

const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0; /* Default spacing */
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;

const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;
