import React, { useState, useEffect } from "react";
import "../../../style/otpheader.css";
import styled from "styled-components";
import "../../../style/header.css";
import otpbg1 from "../../../assets/img/background/otpbg1.png";
import otpbg2 from "../../../assets/img/background/otpbg2.png";
import otpbg3 from "../../../assets/img/background/otpbg3.png";

export default function Header() {
  const images = [otpbg1, otpbg2];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <>
      <Wrapper>
        <div className="header personal-hero">
          <div className="header-text ">
            <h6 className="head-title">
              OTP Service For <span className="extraBold">Business </span>
            </h6>
            <p className="subtitle">
              Authenticate and verify your users using SMS OTP, Whatsapp OTP,
              and Email OTP services
            </p>
            <button className="trail-btn five">Start Your Free Trial</button>
          </div>
          <div>
            <img
              className="head-bg"
              src={images[currentImageIndex]}
              alt="OTP Background"
            />
          </div>
        </div>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 700px;
  position: relative;
  background-color: #f5f5f5;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
