import React, { useState } from "react";

function OtpPopup() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [otpSent, setOtpSent] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const sendOtp = () => {
    const URL = "https://otpapp-73lo.onrender.com/otpDemo/generate";
    const data = {
      email: email,
    };
    fetch(URL, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          setErrorMsg("");
          setSuccessMsg(data.message);
          setOtpSent(true);
        });
      } else {
        response.json().then((data) => {
          setSuccessMsg("");
          setErrorMsg(data.message);
        });
      }
    });
  };

  const verifyOtp = () => {
    const URL = "https://otpapp-73lo.onrender.com/otpDemo/verify";
    const data = {
      email: email,
      otp: otp.join(""), // Join the OTP array to form a single string
    };
    fetch(URL, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          setSuccessMsg(data.message);
        });
      } else {
        response.json().then((data) => {
          setErrorMsg(data.message);
        });
      }
    });
  };

  const handleOtpChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value.replace(/[^0-9]/g, ""); // Restrict input to numbers
    setOtp(newOtp);

    // Automatically focus on the next input
    if (value && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`)?.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`)?.focus();
      }
    }
  };

  return (
    <>
      <h1 className="head-ing">Get Started</h1>
      <div className="form-popup">
        <form action="" className="form-container">
          {errorMsg && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <span className="block sm:inline">{errorMsg}</span>
              <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                <svg
                  className="fill-current h-6 w-6 text-red-500"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  onClick={() => setErrorMsg("")}
                >
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>
          )}

          {successMsg && (
            <div
              className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <span className="block sm:inline">{successMsg}</span>
              <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                <svg
                  className="fill-current h-6 w-6 text-green-500"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  onClick={() => setSuccessMsg("")}
                >
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>
          )}

          <input
            type="email"
            placeholder="E-mail"
            className="email-in"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            // disabled={otpSent}
          />
          {otpSent && (
            <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
              {otp.map((value, index) => (
                <input
                  key={index}
                  id={`otp-input-${index}`}
                  type="text"
                  maxLength="1"
                  value={value}
                  onChange={(e) => handleOtpChange(e.target.value, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  style={{
                    width: "40px",
                    height: "40px",
                    textAlign: "center",
                    fontSize: "18px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                  }}
                />
              ))}
            </div>
          )}
          {otpSent && (
            <button
              type="button"
              className="sub-btn primaryBg"
              onClick={verifyOtp}
            >
              Verify OTP
            </button>
          )}
          {!otpSent && (
            <button
              type="button"
              className="sub-btn primaryBg"
              onClick={sendOtp}
            >
              Send OTP
            </button>
          )}
        </form>
      </div>
    </>
  );
}

export default OtpPopup;
