import React, { useState } from "react";
import styled from "styled-components";
import "../../style/header.css";
import iotBW from "../../assets/img/icons/iot.png";
import iotColor from "../../assets/img/icons/iot-color.png";
import iotBg from "../../assets/img/background/iot-bg.jpg";
import otpBW from "../../assets/img/icons/otp.png";
import otpColor from "../../assets/img/icons/otp-color.png";
import otpBg from "../../assets/img/background/otp-bg.jpg";

export default function Header() {
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const icons = [
    {
      id: "iot",
      heading: "IoT",
      bw: iotBW,
      color: iotColor,
      background: iotBg,
      text: "This is Iot",
      buttonLink: "/iot",
    },
    {
      id: "otp",
      heading: "OTP",
      bw: otpBW,
      color: otpColor,
      background: otpBg,
      text: "This is OTP",
      buttonLink: "/otp",
    },
  ];

  return (
    <>
      <div className="icon-container">
        <h2 className="title">Our services</h2>
        <div
          className="background"
          // style={{
          //   backgroundImage: hoveredIcon
          //     ? `url(${hoveredIcon.background})`
          //     : "none",
          // }}
        />

        {icons.map((icon) => (
          <div
            key={icon.id}
            className={`icon-wrapper ${
              hoveredIcon?.id === icon.id ? "hovered" : ""
            }`}
          >
            <div
              onMouseEnter={() => setHoveredIcon(icon)}
              onMouseLeave={() => setHoveredIcon(null)}
            >
              <img
                src={hoveredIcon?.id === icon.id ? icon.color : icon.bw}
                alt={icon.text}
                className="icon"
              />
              <h3>{icon?.heading}</h3>
              {hoveredIcon?.id === icon.id && (
                <div className="overlay">
                  <p className="domain">{icon.text}</p>
                  <a href={icon.buttonLink} className="fill">
                    Learn More
                  </a>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 660px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
