import React, { useState } from "react";

const ContactUs = () => {
  const [name,setName] =useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [message,setMessage] = useState("");
  const [errorMsg,setErrorMsg] = useState("");
  const[successMsg,setSuccessMsg] = useState("");

  const clearData = () => {
    setSuccessMsg('We received you request')
    setName('');
    setMobileNo('');
    setMessage('');
  }
  const handleSubmit = () => {
    if(name === "" || mobileNo === ""){
      setErrorMsg('Enter you Name and Mobile No')
    }else {
      setErrorMsg("")
      const URL = "https://script.google.com/macros/s/AKfycbyoeH1WNLi4cRwVk5tpDrbqKtT-cLx6JsUrp9helwxx6sQy-trta2yN9R7GnNiaQgP4tQ/exec"
      const data = {
        "values":[[name, mobileNo, message]]
      }
      fetch(URL, {
        redirect: "follow",
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "text/plain;charset=utf-8",
        },
      }).then(
        clearData()
      );
  }
  }
  return (
    <section id="contact" className="py-20 bg-gray-100">
      <h2 className="text-4xl font-semibold py-10 text-gray-800 text-center">Contact Us</h2>  
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10  items-center">
          <div className="space-y-6">
            <h2 className="text-4xl font-semibold text-gray-800">
              Get in Touch
            </h2>
            <p className="text-lg text-gray-600">
              Have a question or want to start a project? We’d love to hear from
              you! Fill out the form, and we’ll get back to you as soon as
              possible.
            </p>
            <p className="text-lg text-gray-600">
              Or reach us directly at{" "}
              <a
                href="mailto:info@decivise.com"
                className="text-indigo-600 hover:underline"
              >
                info@decivise.com
              </a>
              .
            </p>
          </div>

          <div>
            <div className="space-y-6 bg-white p-6 rounded-lg shadow-md">
              {errorMsg !== "" && (
              <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                <span class="block sm:inline">{errorMsg}</span>
                <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
                  <svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" onClick={()=>setErrorMsg("")}><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                </span>
              </div>
              )}

            {successMsg !== "" && (
              <div class="bg-green-100 border border-green-400 text-reen-700 px-4 py-3 rounded relative" role="alert">
                <span class="block sm:inline">{successMsg}</span>
                <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
                  <svg class="fill-current h-6 w-6 text-green-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" onClick={()=>setSuccessMsg("")}><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                </span>
              </div>
              )}  
              
              <input
                type="text"
                placeholder="Your Name"
                className="w-full p-4 border rounded-md focus:ring focus:ring-indigo-500"
                onChange={(e) => (setName(e.target.value))}
                value={name}
              />
              <input
                type="text"
                placeholder="Your Mobile"
                className="w-full p-4 border rounded-md focus:ring focus:ring-indigo-500"
                onChange={(e) => (setMobileNo(e.target.value))}
                value={mobileNo}
              />
              <textarea
                placeholder="Your Message"
                className="w-full p-4 border rounded-md focus:ring focus:ring-indigo-500"
                rows={4}
                onChange={(e) => (setMessage(e.target.value))}
                value={message}
              />
              <button
                onClick={()=> handleSubmit()}
                className="w-full px-6 py-3 primaryBg text-white rounded-md hover:bg-blue-800 transition"
              >
                Send Message
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
