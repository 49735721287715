import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
import withOverlayPopup from "../withOverlayPopup";
// Assets
import LogoIcon from "../../assets/svg/Logo";
import BurgerIcon from "../../assets/svg/BurgerIcon";

const TopNavbar = ({openPopup}) => {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className="flexCenter animate whiteBg">
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="home" smooth={true}>
            <LogoIcon />
            <h1 style={{ marginLeft: "0px" }} className="font25 extraBold">
              Decivise
            </h1>
          </Link>
          <BurderWrapper
            className="pointer"
            onClick={() => toggleSidebar(!sidebarOpen)}
          >
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            <li className="semiBold font18 pointer">
              <a href="/" style={{ padding: "10px 15px" }}>
                Home
              </a>
            </li>
            <li className="semiBold font18 pointer">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="services"
                smooth={true}
                offset={-80}
              >
                Services
              </Link>
            </li>
            <li className="semiBold font18 pointer">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="about-us"
                smooth={true}
                offset={-80}
              >
                About Us
              </Link>
            </li>
            <li className="semiBold font18 pointer">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="blog"
                smooth={true}
                offset={-80}
              >
                Blog
              </Link>
            </li>

            <li className="semiBold font18 pointer">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="contact"
                smooth={true}
                offset={-80}
              >
                Contact
              </Link>
            </li>
          </UlWrapper>
          <UlWrapperRight className="flexNullCenter">
            {/* Login button */}
            {/* <li className="semiBold font15 pointer">
              <a href="/" style={{ padding: "10px 30px 10px 0" }}>
                Log in
              </a>
            </li> */}
            <li className="semiBold font18 pointer flexCenter">
              <Link onClick={openPopup}
                to="contact"
                className="radius8 lightBg"
                style={{ padding: " 12px 10px" }}
              >
                Get Started for Free
              </Link>
            </li>
          </UlWrapperRight>
        </NavInner>
      </Wrapper>
    </>
  );
}

export default withOverlayPopup(TopNavbar);
const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgb(255, 255, 255); /* light Blue Background */
  height: 80px; /* height */
  /* Remove the box-shadow to eliminate the line */
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
`;

const NavInner = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center the content horizontally */
`;

const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;

const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
  li a {
    color: #111d57; /* Dark Blue Text */
    transition: color 0.3s ease;
    &:hover {
      color: #64d2d4; /* Medium Blue on Hover */
    }
  }
`;

const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }

  .radius8 {
    background-color: #1e9bff; /* Button Background Blue */
    color: #ffffff;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 10px; /* Ensures the edges are rectangular */
    text-align: center; /* Correct text alignment */
    padding: 10px 20px; /* Optional: add padding for better button size */
    cursor: pointer; /* Optional: add pointer cursor for button */

    &:hover {
      background-color: #111d57; /* Hover Button Blue */
      color: #e1f0ff;
    }
  }
`;

// /* Modify the Logo Text */
// const LogoText = styled.h1`
//   color: #d9e6f6; /* Light Blue for Logo */
//   font-size: 20px;
//   font-weight: 800;
//   margin-left: 15px;
// `;
